<template>
  <tr
    @click="toggleTools"
    v-click-outside="closeTools"
    :class="{
      'table-row--show-tools': showTools,
      'table-row--highlight': highlight,
      'table-row--error': row_error
    }"
  >
    <slot />
  </tr>
</template>

<script>
// import Btn from '@/components/base/Btn.vue'

export default {
  name: 'TableRow',

  components: {
    // Btn,
  },

  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
    tools: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    row_error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showTools: false,
    }
  },

  methods: {
    toggleTools() {
      if (this.edit && !this.showTools) {
        this.$emit('edit-item', this.selectedItems)
      }
      if (this.tools && !this.edit) {
        this.showTools = !this.showTools
      }
      if (this.showTools && this.edit) {
        this.showTools = false
      }
    },
    closeTools() {
      this.showTools = false
    },
  },
}
</script>

<style lang="scss" scoped>
/* TABLE ROW */
</style>
