<template>
  <!-- Traitement fertilisation -->
  <div class="fiche-pesees-ferti-edit-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Fiche de pesées</h2>
      </Container>
    </div>
    <Section>
      <Container>
        <div class="btn-bar btn-bar--right btn-bar--sub-h2">
          <Btn color="primary" hollow text="Paramètre de la fiche" @click="openModal('params', parameters)" />
          <Btn color="primary" hollow text="Exporter la fiche de pesées"
            @click="download('xls', 'evenement', $route.params.tid)" />
        </div>

        <form>
          <Table :headers="headers" :static-headers="staticHeaders" :items="formattedModalities"
            :hiddenItems="['id', 'ordre', 'dose_epandue_unite', 'suffixe']" :tools="true"
            @table-row-validate="validate($event)">
            <template v-slot:tools="{ item }">
              <Btn @click="openModal('edit', item.item)" icon="create" color="white" title="Modifier la modalité" />
            </template>

          </Table>
        </form>
      </Container>
    </Section>

  </div>

  <!-- Modale pour une modalité -->
  <Modal type="global" title="Edition d'une modalité" :active="modal.edit" :data="modalData"
    @modal-close="modal.edit = false" :containForm="true" :modalValidationSchema="modaliteSchema"
    @modal-form-submitted="onSubmit(modalData.id, $event)">
    <template v-slot:modal-body="{ data }">

      <SelectExtended label="Elément de référence" id="composant_uid" option-key="uid" option-value="nom"
        apiEndpoint="composant" />

      <Input label="Dose épandue: Valeur" id="dose_epandue" type="number" :options="{ min: 0, step: 0.001 }" />

      <SelectExtended label="Dose épandue: Unité" id="unite_uid"
        :apiEndpoint="getUnitesDoseEpandueEndpoint('KILOHECTARE', 'TONNEHECTARE', 'LITREHECTARE')" option-key="uid"
        option-value="valeur" />

      <div class="input-text input-text--error no-margin-top" v-if="peseeErrors?.unite_uid">
        {{ peseeErrors?.unite_uid }}
      </div>

      <Input label="Densité" id="densite" type="number" :options="{ min: 0, step: 0.001 }" />

      <Input label="Pesée réelle" id="pesee_reelle" type="number" :options="{ min: 0, step: 0.001 }"
        :inputAfter="data?.suffixe" />

      <Input type="textarea" label="Observations" id="observations" />

    </template>

    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.edit = false" />
      <Btn text="Enregistrer" color="primary" type="submit" />
    </template>

  </Modal>

  <!-- Modale des paramétrages de la fiche de pesées -->
  <Modal type="parameters" v-if="parameters" :title="evenement_nom ?
    `Paramétrage de la fiche de pesées : ${evenement_nom}` :
    `Paramétrage de la fiche de pesées`" :active="modal.params" :data="modalData" @modal-close="modal.params = false"
    :containForm="true" :modalValidationSchema="parametersSchema" @modal-form-submitted="setParameters($event)">
    <template v-slot:modal-body>

      <Input label="Marge de sécurité pesées" type="number" input-after="%" :options="{ step: 0.001, min: 0, max: 100 }"
        id="marge_securite_pesee" />

      <SelectExtended label="Elément de référence" id="composant_uid" option-key="uid" option-value="nom"
        apiEndpoint="composant" />

      <SelectExtended label="Unité dose épandue" id="unite_dose_epandue_id" option-key="id" option-value="valeur"
        :apiEndpoint="getUnitesDoseEpandueEndpoint('UFERTI', 'QBRUTE')" />

      <Radio v-if="unites" label="Unité de calcul quantités" id="unite_uid" :items="unites" inputStyle="inline" />

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.params = false" />
      <Btn text="Enregistrer" color="primary" type="submit" />
    </template>

  </Modal>

  <Loader :active="loading" />

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Modal from '@/components/layout/Modal.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Loader from '@/components/layout/Loader.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'FichePeseesFertiSubview',
  components: {
    SelectExtended,
    Btn,
    Table,
    Container,
    Input,
    Modal,
    Radio,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const parametersSchema = this.yup.object().shape({
      marge_securite_pesee: this.yup.string().nullable(),
      unite_uid: this.yup.object().nullable(),
      composant_uid: this.yup.object().nullable(),
      unite_dose_epandue_id: this.yup.object().nullable(),
    })

    const modaliteSchema = this.yup.object().shape({
      composant_uid: this.yup.object().nullable(),
      dose_epandue: this.yup.string().nullable(),
      unite_uid: this.yup.object().nullable(),
      densite: this.yup.string().nullable(),
      pesee_reelle: this.yup.string().nullable(),
      observations: this.yup.string().nullable(),
    })

    return {
      parametersSchema,
      modaliteSchema,
      modal: {
        params: false,
        edit: false,
      },
      modalData: null,
      loading: true,
      headers: [],
      staticHeaders: [
        'Produit',
        'Elément de <br>référence',
        'Taux composition <br>élément de référence',
        'Dose <br>épandue',
        'Nb rép.',
        'Surface <br>application',
        'Densité',
        'Quant. calculée <br>par microparcelle',
        'Pesée réelle',
        'Réalisé <br>par',
        'Réalisé <br>le',
        'Observations',
        'Validation',
      ],
      modalities: {},
      formattedModalities: [],
      fiche_pesee_id: null,
      evenement_nom: null,
      unites: [],
      unites_dose_epandue: [],
      parameters: null,
      componentKey: 0,
      peseeErrors: null,
    }
  },
  created() {
    this.loading = true
    this.setComponent()
  },

  methods: {
    validate(event) {
      const values = { ...event }
      // eslint-disable-next-line
      values.trigramme = (this.$store.state.auth.user.data.prenom.substring(0, 2) + this.$store.state.auth.user.data.nom.substring(0, 1)).toUpperCase()
      values.date = this.helperService.formatDateForApi(new Date())
      this.onSubmit(values.id, values)
    },

    openModal(modal, data) {
      this.modalData = data
      if (modal === 'edit') {
        this.modalData = this.getMods(data)
      }
      this.modal[modal] = true
    },

    setComponent() {
      this.emitter.emit('open-loader')
      this.getModalites().then((rm) => {
        this.modalities = rm
        this.getFormatModalities(this.modalities).then((rfm) => {
          this.formattedModalities = rfm
          this.setFicheParams()
          this.emitter.emit('close-loader')
        })
      })
    },

    setFicheParams() {
      if (this.fiche_pesee_id) {
        const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/fertilisation/pesee/${this.fiche_pesee_id}`
        this.fetchService.get(endpoint).then((res) => {
          const datas = res.data
          datas.unite_uid = datas.unite?.uid
          if (!datas.unite_uid) {
            datas.unite_uid = this.parseParameterValue('FERTI_PESEE_UNITECALCUL').key
          }
          if (!datas.marge_securite_pesee) {
            datas.marge_securite_pesee = this.parseParameterValue('FERTI_PESEE_MARGESECURITEPESEE')
          }
          datas.composant_uid = {
            key: datas.composant?.uid,
            value: datas.composant?.nom,
          }
          if (!datas.composant_uid.key) {
            datas.composant_uid = this.parseParameterValue('FERTI_PESEE_ELEMENTREFERENCE')
          }

          datas.unite_dose_epandue_id = {
            key: datas.unite_dose_epandue?.id,
            value: datas.unite_dose_epandue?.valeur,
          }

          if (!datas.unite_dose_epandue_id.key) {
            datas.unite_dose_epandue_id = this.parseParameterValue('FERTI_PESEE_UNITEDOSEEPANDUE', 'id')
          }
          this.parameters = datas
          this.getUnites()
        })
      }
    },

    parseParameterValue(parameterName, attribute = null) {
      try {
        const parameterValue = JSON.parse(this.$store.getters['app/parameters']([parameterName])[0].valeur)

        if (parameterName === 'FERTI_PESEE_UNITEDOSEEPANDUE') {
          const endpoint = this.getUnitesDoseEpandueEndpoint(parameterValue.key)
          this.fetchService.get(endpoint).then((res) => {
            const response = res.data.pop()
            parameterValue.key = response[attribute]
            return parameterValue
          })
        }
        // eslint-disable-next-line
        return typeof parameterValue === 'string' && parameterValue ? parameterValue : typeof parameterValue === 'object' && parameterValue ? parameterValue : null
      } catch (e) {
        console.log(e)
        return null
      }
    },

    getMods(modalitie) {
      return {
        id: modalitie.id,
        composant_uid: modalitie.element_reference,
        dose_epandue: this.taskService.getValueOnly(modalitie.dose_epandue),
        unite_uid: modalitie.dose_epandue_unite,
        densite: modalitie.densite,
        pesee_reelle: this.taskService.getValueOnly(modalitie.pesee_reelle),
        trigramme: modalitie.trigramme,
        date: modalitie.date || null,
        observations: modalitie.observations,
        suffixe: modalitie.suffixe,
      }
    },

    getModalites() {
      return new Promise((resolve) => {
        const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/fertilisation/pesee/modalite`
        this.fetchService.get(endpoint, { limit: 0 }).then((res) => {
          resolve(res.data)
        })
      })
    },

    getFormatModalities(modalities) {
      return new Promise((resolve) => {
        let datas = []
        modalities.forEach((modality) => {
          const data = {
            id: modality.id,
            ordre: modality.modalite?.ordre,
            produit: modality.produit?.designation,
            element_reference: modality.composant
              ? { key: modality.composant.uid, value: modality.composant.nom }
              : { key: null, value: null },
            tx: modality.composition?.pivot?.quantite,
            dose_epandue: this.taskService.concatValueUnit(
              modality.dose_epandue ?? 'NC',
              modality.unite ? modality.unite.valeur : '',
            ),
            dose_epandue_unite: modality.unite
              ? { key: modality.unite.uid, value: modality.unite.valeur }
              : { key: null, value: null },
            nb_repetitions: modality.nb_repetitions,
            surface_application: this.taskService.concatValueUnit(
              modality.surface_application ?? 'NC',
              modality.surface_application ? 'm²' : '',
            ),
            densite: modality.densite,
            quantite_microparcelle: this.taskService.concatValueUnit(
              modality.quantite_microparcelle ? this.helperService.round(modality.quantite_microparcelle, 3) : 'NC',
              modality.quantite_microparcelle ? modality.fiche_fertilisation_pesee?.unite?.valeur : '',
            ),
            pesee_reelle: this.taskService.concatValueUnit(
              modality.pesee_reelle ? this.helperService.round(modality.pesee_reelle, 3) : 'NC',
              modality.pesee_reelle ? modality.fiche_fertilisation_pesee?.unite?.valeur : '',
            ),
            suffixe: modality.fiche_fertilisation_pesee?.unite?.valeur,
            trigramme: modality.trigramme,
            date: modality.date?.split(' ').shift(),
            observations: modality.observations,
            validation: modality.date !== null && modality.trigramme !== null,
          }
          this.fiche_pesee_id = modality.fiche_fertilisation_pesee?.id
          this.evenement_nom = modality.evenement_designation
          this.unites_dose_epandue = modality.fiche_fertilisation_pesee?.unite
          datas.push(data)
        })

        datas = this.helperService.sortByOrder(datas)
        this.loading = false
        resolve(datas)
      })
    },

    download(type, ressource, rid) {
      this.loading = true
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/fiche-pesee`
      const filename = `evenement_${rid}_fiche_pesee_fertilisation`
      this.fileService.downloadFile(endpoint, filename, type)
      this.loading = false
    },

    async setParameters(parameters) {
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] === '') {
          // eslint-disable-next-line no-param-reassign
          parameters[key] = null
        }
      })
      if (this.fiche_pesee_id) {
        // eslint-disable-next-line no-param-reassign
        parameters.composant_uid = parameters.composant_uid?.key
        // eslint-disable-next-line no-param-reassign
        parameters.unite_dose_epandue_id = parameters.unite_dose_epandue_id?.key
        const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/fertilisation/pesee/${this.fiche_pesee_id}`
        const response = await this.fetchService.put(endpoint, parameters)
        const metas = await response.meta
        if (metas.success) {
          this.setComponent()
          this.modal.params = false
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Les paramètres de la fiche de pesées ont bien été modifiés.',
          })
        }
      }
    },

    async onSubmit(id, values) {
      const params = { ...values }
      params.date = params.date
        ? this.helperService.formatDateForApi(params.date.slice(0, 10))
        : null
      params.trigramme = params.trigramme ? params.trigramme : null
      params.composant_uid = values.composant_uid?.key
      params.unite_uid = values.unite_uid?.key
      params.pesee_reelle = this.taskService.getValueOnly(values.pesee_reelle)
      params.dose_epandue = this.taskService.getValueOnly(values.dose_epandue)

      Object.keys(params).forEach((key) => {
        if (params[key] === '') {
          params[key] = null
        }
      })

      const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/fertilisation/pesee/modalite/${id}`
      try {
        const response = await this.fetchService.put(endpoint, params)
        if (response.meta.success) {
          this.mods = []
          this.loading = true
          this.peseeErrors = null
          this.setComponent()
          this.modal.edit = false
          this.emitter.emit('alert', {
            type: 'success',
            content: `La pesée ${id} a bien été modifiée.`,
          })
        }
      } catch (error) {
        const resError = await error.data
        if (resError.fields) {
          this.peseeErrors = {}
          Object.keys(resError.fields).forEach((key) => {
            this.peseeErrors[key] = resError.fields[key].pop()
          })
        }
      }
    },

    getUnites() {
      this.fetchService.get('unite?filters=uid:in(KILOGRAMME,GRAMME)').then((ru) => {
        const datas = ru.data
        const result = []
        datas.forEach((data) => {
          result.push({
            label: data.valeur,
            value: data.uid,
          })
        })
        this.unites = result
      })
    },

    getUnitesDoseEpandueEndpoint(...unites) {
      const u = unites.join(',')
      return `unite?filters=uid:in(${u})`
    },
  },
}
</script>

<style lang="scss" scoped>
.no-margin-top {
  margin-top: -3rem;
}
</style>
