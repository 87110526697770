<template>
  <div class="table table--auto-zebra" v-bind:class="allTableClass" :data-simple-layout="simpleLayout"
    v-on:scroll="onScroll">
    <table>
      <thead>
        <tr class="table-row--auto-zebra">
          <th v-if="showModalitiesNumber" rowspan="2" :colspan="checkbox ? 2 : 1" :class="{
            'table-cell--sticky-col': stickyHeader,
          }">
            Modalités
          </th>
          <th v-for="(header, index) in headers" :class='{
            "table-head--disabled": header.disabled ?? false,
            "table-head--bglight": header.bglight ?? false,
            "table-head--bgdark": header.bgdark ?? false,
          }' :key="index" :colspan="header?.sub_headers ? header.sub_headers.length : null"
            :data-index='"header-" + index' :rowspan="header?.sub_headers && header?.sub_headers.length ? 1 : 2">
            <slot :name="(header.slotName && header.slotName !== '') ? header.slotName : 'header'" :item="header">
              {{ header?.type?.designation }}
              {{ header?.designation }}
            </slot>
          </th>
          <template v-if="staticHeaders">
            <template v-for="(staticHeader, index) in staticHeaders" :key="index">
              <th rowspan="2" v-html="staticHeader"></th>
            </template>
          </template>
          <slot name="headers"></slot>
        </tr>
        <tr>
          <template v-for="(header, headerindex) in headers" :key="headerindex">
            <th :class="{
              'table-head--odd': !isExportTable ? headerindex % 2 !== 0 : (headerindex + 1) % 2 !== 0,
              'table-subhead--disabled': header.disabled ?? false,
              'table-subhead--bglight': header.bglight ?? false,
              'table-subhead--bgdark': header.bgdark ?? false
            }" v-for="(subHeader, index) in header.sub_headers" :key="index">
              {{ subHeader }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <!-- 1 -->
        <TableRow v-if="items.length === 0" :tools="false" :edit="edit">

          <td :colspan="headers.map((header) => (header?.sub_headers ? header.sub_headers : null)).flat().length +
            (staticHeaders ? staticHeaders.length : 0)
            + 1 + (checkbox ? 1 : 0)" class="table-cell--empty-table">
            Aucun élément à afficher actuellement
          </td>

        </TableRow>
        <TableRow v-else v-for="(item, index) in items" :key="index" :tools="tools" :row_error="item.row_error"
          :highlight="item.highlight" :edit="edit" @editItem="editItem(item)">

          <td v-if="showModalitiesNumber && checkbox" :class="{
            'table-cell--sticky-col': stickyColumn,
          }">
            <template v-if="checkbox">
              <Checkbox :modelValue="selectedItems" :name="`list-item-${item[checkboxKey]}`"
                :id="`checkbox-${item[checkboxKey]}`" :items="[{ value: item[checkboxKey] }]"
                @update:modelValue="onCheckboxChange($event, item[checkboxKey])" />
            </template>
          </td>

         <td v-if="showModalitiesNumber" :class="{ 'table-cell--sticky-col': (stickyColumn && !checkbox ), }">
            <template v-if="item.ordre">
              <div v-if="item.ordre !== '---'" class="table-index" :class="{
                'table-index--optional': item.option
              }">
                {{ item.ordre ?? index + 1 }}
              </div>
            </template>

          </td>

          <template v-for="(property, index) in Object.keys(item)" :key="index">
            <slot :name="property" :item="{ item }">
              <td v-if="helperService.isObject(item[property])" v-show="!hiddenItems || !hiddenItems.includes(property)"
                v-html="item[property]?.value"></td>
              <td v-else-if="property.includes('date') && item[property]"
                v-show="!hiddenItems || !hiddenItems.includes(property)" v-html="new Date(item[property]).toLocaleDateString('fr-FR', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
                )"></td>
              <td v-else-if="property === 'validation'" v-show="!hiddenItems || !hiddenItems.includes(property)">
                <Btn class="icon-validation" @click.stop="$emit('table-row-validate', item)" icon="thumbs-up"
                  title="Valider" :class="{
                    'icon-validation--active': item.validation
                  }" :disabled="item.validation" />
              </td>
              <td v-else-if="property === 'conformite'" v-show="!hiddenItems || !hiddenItems.includes(property)">
                <span :class="{
                  'conformite-success': item.conformite === 'Conforme',
                  'conformite-error': item.conformite === 'Non conforme',
                }">
                  {{ item.conformite }}
                </span>
              </td>
              <td v-else :class="{
                'table-cell--disabled': index - 3 > 0 ? headers[index - 3]?.disabled : false
              }" v-show="!hiddenItems || !hiddenItems.includes(property)" v-html="item[property]">
              </td>
            </slot>

          </template>

          <td class="table-col-tools" v-if="tools || !checkbox">
            <div class="table-col-tools-wrapper">
              <slot name="tools" :item="{ item }"></slot>
            </div>
          </td>

        </TableRow>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableRow from '@/components/table/TableRow.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'Table',

  components: {
    Checkbox,
    TableRow,
    Btn,
  },

  props: {
    tableClass: {
      type: String,
      default: null,
    },
    headers: {
      type: Object,
    },
    staticHeaders: {
      type: Array,
      default: null,
    },
    items: {
      type: Object,
    },
    hiddenItems: {
      type: Array,
      default: null,
    },
    defaultSelectedItems: {
      type: Array,
      default: null,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    showModalitiesNumber: {
      type: Boolean,
      default: true,
    },
    /**
     * Key used for the loop
     *
     * @default uid
     * @example id, uid
     */
    checkboxKey: {
      type: String,
      default: 'uid',
    },
    dragAndDrop: {
      type: Boolean,
      default: false,
    },
    tools: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: Boolean,
      default: true,
    },
    stickyColumn: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    simpleLayout: {
      type: Boolean,
      default: false,
    },
    isExportTable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Direction
      lastScroll: 0,
      scrollDirection: 'none',
      scrollPosition: 'top',
      scrollHeight: 0,
      scrollOffset: 200,
      ticking: false,
      selectedItems: [],
      allTableClass: '',
    }
  },

  emits: ['table-row-validate', 'selected-items', 'edit-item'],

  watch: {
    edit() {
      if (this.edit) {
        this.allTableClass = `${this.tableClass} table--edit`
      } else {
        this.allTableClass = this.tableClass
      }
    },
  },
  mounted() {
    // console.log('icicici', this.items)
    this.selectedItems = this.defaultSelectedItems
      ? this.defaultSelectedItems
      : []

    this.$emit('selected-items', this.selectedItems)
  },

  methods: {
    onScroll(event) {
      // Run only for vertical scroll
      if (event.target.scrollTop !== this.lastScroll) {
        // Compute scroll offset, direction and position
        this.scrollDirection = event.target.scrollTop < this.lastScroll ? 'up' : 'down'
        this.lastScroll = event.target.scrollTop

        if (this.lastScroll < this.scrollOffset) {
          this.scrollPosition = 'top'
        } else if (this.lastScroll > this.scrollHeight - this.scrollOffset) {
          this.scrollPosition = 'bottom'
        } else {
          this.scrollPosition = 'middle'
        }

        // Event throttling (one per tick maximum)
        if (!this.ticking) {
          window.requestAnimationFrame(() => {
            // console.log(event)
            this.ticking = false
            this.scrollHeight = event.target.scrollHeight - event.target.offsetHeight

            // Fire event
            this.emitEventScroll()
          })

          this.ticking = true
        }
      }
    },
    editItem(item) {
      this.$emit('edit-item', item)
    },

    // EVENT EMITTING
    emitEventScroll() {
      // console.log(
      //   `SCROLL: ${this.lastScroll}px / ${this.scrollHeight}px ` +
      //   `(${Math.round((this.lastScroll / this.scrollHeight) * 100)} %) ` +
      //   `- Dir: ${this.scrollDirection} - Pos: ${this.scrollPosition}`
      // )

      this.emitter.emit('scroll', {
        scrollDirection: this.scrollDirection,
        scrollPosition: this.scrollPosition,
        scrollOffset: this.lastScroll,
      })
    },

    onCheckboxChange(event, key) {
      const index = this.selectedItems.indexOf(key)
      if (index > -1) {
        this.selectedItems.splice(index, 1)
      } else {
        this.selectedItems.push(key)
      }

      this.emitter.emit('selected-items', { selected: this.selectedItems })
    },
  },
}
</script>

<style lang="scss" scoped>
/* TABLE */

// Variables

$table-bg-color-row-head-odd: $color-primary;
$table-bg-color-row-head-even: $color-primary-lighty;
$table-bg-color-cell-full-sticky: $color-primary-dark;
$table-bg-color-row-highlight: $color-accent-lightest;
$table-color-row-highlight: black;
$table-bg-color-row-odd: white;
$table-bg-color-row-even: #f6fff7;
$table-bg-color-table-col-tools: rgba(0, 0, 0, 0.75);

$table-bg-color-row-head-bgdark: adjust-hue($color-primary-lighty,18deg);
$table-bg-color-row-head-bglight: adjust-hue($color-primary-lighty,-18deg);

$table-border-size: 1px;
$table-border-color: $table-bg-color-cell-full-sticky;
$table-border-color-head: $color-primary-darky;
$table-border-color-body: $color-gray-lighter;

.icon-validation {
  color: $color-gray;
}

.icon-validation--active {
  color: $color-primary;

  &:disabled {
    opacity: 1;
  }
}

// Table wrapper

.table-head--disabled {}

.table-cell--disabled

/*,
.table-subhead--disabled*/
  {
  // opacity: 0.5;
  // filter: grayscale(50%);

  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background: repeating-linear-gradient(45deg,
        transparent,
        transparent 2rem,
        rgba(246, 246, 246, 0.75) 2rem,
        rgba(246, 246, 246, 0.75) 4rem);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.table {
  width: 100%;
  height: 100%;
  overflow: auto;

  &[data-simple-layout="true"] {
    height: auto;
    overflow: initial;
    overflow-x: auto;
    margin-bottom: $gutter;
  }
}

// The real table

table {
  border-collapse: separate;
  border-spacing: 0;
  cell-spacing: 0;
  white-space: nowrap;
  font-size: 1.1rem;
  min-width: 100%;
}

// Table cells (th and td)

td,
th {
  border-bottom: $table-border-size solid;
  border-right: $table-border-size solid;
  border-color: $table-border-color;
  padding: $gutter-quarter $gutter-quarter;
  text-align: center;

  &.table-cell--sticky-col {
    border-left: $table-border-size solid $table-border-color;
  }

  &:nth-last-child(2) {
    border-right: $table-border-size solid $table-border-color !important;
  }

  &.table-cell--empty-table {
    border-left: $table-border-size solid $table-border-color;
    border-right: $table-border-size solid $table-border-color;
  }
}

// Table Head

thead {
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;

  th {
    background: $table-bg-color-row-head-even;
    border-color: $table-border-color-head;

    &.table-head--odd {
      background: $table-bg-color-row-head-odd;
    }

    &.table-subhead--bgdark {
      background-color:  $table-bg-color-row-head-bgdark;
    }

    &.table-subhead--bglight {
      background-color:  $table-bg-color-row-head-bglight;
    }

    &.table-head--static {
      background: $table-bg-color-cell-full-sticky;
    }

    span {
      font-weight: $font-weight-normal;
    }
  }

  tr {

    // Main head row
    &:first-child {
      th {
        border-top: $table-border-size solid $table-border-color;

        // Full-sticky cell (top-left)
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          background-color: $table-bg-color-cell-full-sticky;
        }
      }
    }

    // Other head row(s)
    &:not(:first-child) {
      th {
        font-weight: $font-weight-normal;
      }
    }

    &.table-row--auto-zebra {
      th:not(.table-cell--sticky-col) {
        &:nth-child(even) {
          background-color: $table-bg-color-row-head-even;
        }

        &:nth-child(odd) {
          background-color: $table-bg-color-row-head-odd;
        }

        &.table-head--bgdark {
          background-color: $table-bg-color-row-head-bgdark;
        }

        &.table-head--bglight {
          background-color: $table-bg-color-row-head-bglight;
        }

      }
    }
  }
}

// Table body

tbody {

  // Body cells background color
  th,
  td {
    // background: $table-bg-color-row-default;
    border-color: $table-border-color-body;
  }

  th {
    // position: relative;
    position: sticky;
    left: 0;
    background: $table-bg-color-row-odd;
    z-index: 1;
  }

  tr.table-row--even {

    th,
    td {
      background: $table-bg-color-row-even;
    }
  }

  // Tools
  tr {

    // Table bottom main border
    &:last-child {

      th,
      td {
        border-bottom-color: $table-border-color;
      }
    }
  }
}

// Tools column

tbody tr {
  // Tools row layout
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $table-bg-color-table-col-tools;
    transition: all 0.25s;
    z-index: +1;
    opacity: 0;
    pointer-events: none;
  }
}

.table-col-tools {
  position: sticky;
  right: 0;
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  transition: all 0.25s;
  z-index: +2;
  opacity: 0;
  pointer-events: none;

  .table-col-tools-wrapper {
    position: absolute;
    right: $gutter-half;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: $gutter-quarter;
    align-items: center;
  }
}

.table-row--show-tools {
  &::after {
    opacity: 1;
    pointer-events: initial;
  }

  .table-col-tools {
    opacity: 1;
    pointer-events: initial;
  }
}

// Rows zebra

.table--auto-zebra {
  tbody {
    tr {
      &:not(.table-row--highlight) {
        &:nth-child(even) {

          th,
          td {
            background: $table-bg-color-row-even;
          }
        }

        &:nth-child(odd) {

          th,
          td {
            background: $table-bg-color-row-odd;
          }
        }
      }
    }
  }
}

.table-row--highlight {

  th,
  td {
    background-color: $table-bg-color-row-highlight;
    color: $table-color-row-highlight;
  }
}

.table-row--error {

  th,
  td {
    font-weight: $font-weight-bold;
    color: $color-error;
  }
}

.modalitiesTable.table--auto-zebra {
  thead {
    tr.table-row--auto-zebra {
      th:nth-last-child(-n + 5) {
        background: $table-bg-color-cell-full-sticky;
      }
    }

    .facteur-designation {
      display: block;
    }
  }
}

// Hover style while editing / swapping rows
.table--edit {
  tbody {
    tr {
      &:hover {
        filter: brightness(95%) saturate(125%);
        // cursor: move;
      }
    }
  }
}

.conformite-success {
  color: $color-primary
}

.conformite-error {
  color: $color-error
}
</style>
